import Debugger from '../util/Debugger';

const ACTIVE_CLASS = '-active';
const BOTTOM_CLASS = '-bottom';
const FIXED_CLASS = '-fixed';
const MTOP0_CLASS = '-mtop_0';

class FixedAnchor {
  constructor(obj) {
    this.BREAK_POINT = obj.BREAK_POINT;
    this.header = obj.header; // グロナビ
    this.menu = obj.menu;
    this.item = obj.item;
    this.sections = obj.section;
    this.topElm = obj.topElm;
    this.topPoint;
    // this.topPoint = (obj.topElm !== undefined) ? obj.topElm : 0;
    this.bottomElm = obj.bottomElm;
    this.bottomPoint;
    this.sectionPoint = [];
    this.withHeader = obj.withHeader;
  }

  updateDevice() {
    let width = window.innerWidth;
    if (width >= this.BREAK_POINT) {
      if (this.isPC) return;
      this.isPC = true;
    } else {
      if (!this.isPC) return;
      this.isPC = false;
      this.reset();
    }
  }
  reset() {
    this.menu.removeClass(BOTTOM_CLASS);
    this.menu.removeClass(FIXED_CLASS);
  }
  getTriggerPoint() {
    const hederHeight = this.header.outerHeight();
    if (this.withHeader) {
      this.topPoint = this.topElm.offset().top - (hederHeight + 100);
    } else {
      this.topPoint = this.topElm.offset().top;
    }
    const bottomElmTop = this.bottomElm.offset().top;
    const bottomElmHeight = this.bottomElm.height();
    const menuHeight = this.menu.outerHeight(true);
    this.bottomPoint = bottomElmTop + bottomElmHeight - menuHeight;
    if (this.withHeader) this.bottomPoint -= hederHeight; // ヘッダー追従型の場合はヘッダーの高さをマイナス
  }
  getSectionPoint() {
    this.sectionPoint = [];
    for (let i = 0; i < this.sections.length; i++) {
      const section = this.sections.eq(i);
      const sectionTop = section.offset().top;
      const sectionBottom = sectionTop + section.height();
      this.sectionPoint.push(sectionBottom);
    }
  }
  init() {
    if (!this.menu.length > 0) return false;

    this.getTriggerPoint();
    this.getSectionPoint();

    let width = window.innerWidth;
    this.isPC = width >= this.BREAK_POINT;

    // scrolltopがtopTriggerにきたらfixedつける
    $(window).on('scroll', e => {
      if (!this.isPC) return false;
      let scrollTop = $(e.currentTarget).scrollTop();
      if (scrollTop < this.topPoint) {
        this.menu.removeClass(BOTTOM_CLASS);
        this.menu.removeClass(FIXED_CLASS);
      } else if (this.topPoint <= scrollTop && scrollTop <= this.bottomPoint) {
        this.menu.removeClass(BOTTOM_CLASS);
        this.menu.addClass(FIXED_CLASS);
      } else if (this.bottomPoint < scrollTop) {
        this.menu.removeClass(FIXED_CLASS);
        this.menu.addClass(BOTTOM_CLASS);
      }

      // スクロールで該当セクション項目にactive付与
      const menuBottom = this.menu.offset().top + this.menu.height();
      let index = 0;
      for (let i = 0; i < this.sectionPoint.length; i++) {
        if (menuBottom < this.sectionPoint[i]) {
          index = i;
          break;
        }
      }
      // 最終セクションより下の場合は最終セクション番号にする
      if (this.bottomPoint < menuBottom) index = this.sectionPoint.length - 1;
      if (this.item !== undefined) {
        this.item.removeClass(ACTIVE_CLASS);
        this.item.eq(index).addClass(ACTIVE_CLASS);
      }
    });

    $(window).on('resize', () => {
      this.updateDevice();
      this.getTriggerPoint();
      this.getSectionPoint();
    });
  }
}

export default FixedAnchor;
