import Debugger from '../util/Debugger';
import Accordion from './Accordion';

class FooterAccordion extends Accordion {
  constructor(container, obj) {
    super(container, obj);

    this.BREAK_POINT = 768;
  }

  /**
   * 768以上でコンテナの高さをautoにしてアコーディオンは無効化
   * 768以下では高さを0にしてアコーディオンを閉じた状態にセットする
   */
  updateDevice() {
    let width = window.innerWidth;

    if (width >= this.BREAK_POINT) {
      if (this.isPC) return;
      this.isPC = true;
      this.forcedReset('auto');
      Debugger.log('forcedReset auto');
    } else {
      if (!this.isPC) return;
      this.isPC = false;
      this.forcedReset(0);
      Debugger.log('forcedReset 0');
    }
  }
}

export default FooterAccordion;
