'use strict';

import Field from './Field';

/**
 * Validator クラス.
 */
class Validator extends Field {
	constructor() {
		super();

		this.ON_UPDATE = 'update';

		this.STATUS_SUCCESS = 'success';
		this.STATUS_ERROR = 'error';

		this.MESSAGE_ERROR_REQUIRED_INPUT = '入力してください。';
		this.MESSAGE_ERROR_REQUIRED_SELECT = '選択してください。';
		this.MESSAGE_ERROR_MAXLENGTH = '文字以内で入力してください。';
		this.MESSAGE_ERROR_FORMAT_EMAIL = 'メールアドレスの形式（例．sample@sakura-wks.com）で入力してください。';
		this.MESSAGE_ERROR_FORMAT_TEL = '電話番号の形式で入力してください。';

		this.MESSAGE_ERROR_REQUIRED_INPUT_EN = 'The field is required';
		this.MESSAGE_ERROR_REQUIRED_SELECT_EN = 'The field is required';
		this.MESSAGE_ERROR_MAXLENGTH_EN = 'The field is too long';
		this.MESSAGE_ERROR_FORMAT_EMAIL_EN = 'The e-mail address entered is invalid';
		this.MESSAGE_ERROR_FORMAT_TEL_EN = 'The telephone number is invalid';

		// バリデーターのID
		this._id;
		// 必須フラグ
		this._required = false;
		// 許可文字数
		this._maxlength = 0;
		// チェック状態
		this._status;
		// メッセージ
		this._message;
		// 言語
		this._lang;
	}
	get id() {
		return this._id;
	}
	set id(n) {
		this._id = n;
	}
	get required() {
		return this._required;
	}
	set required(b) {
		this._required = b;
	}
	get maxlength() {
		return this._maxlength;
	}
	set maxlength(n) {
		this._maxlength = n;
	}
	get status() {
		return this._status;
	}
	get message() {
		return this._message;
	}
	get lang() {
		return this._lang;
	}
	set lang(s) {
		this._lang = s;
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm 要素
	 */
	init(elm) {
		super.init(elm);

		this.addEventListener(this.ON_BLUR, this.update, this);
		this.addEventListener(this.ON_CHANGE, this.update, this);
	}
	/**
	 * カーソルが外れた、値が変更されたときの処理をおこなう.
	 */
	update() {
	}
}

export default Validator;
