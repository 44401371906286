'use strict';

import EventDispatcher from '../../util/EventDispatcher';

/**
 * Field クラス.
 */
class Field extends EventDispatcher {
	constructor() {
		super();

		this.ON_BLUR = 'blur';
		this.ON_CHANGE = 'change';

		// 要素
		this._element;
	}
	get element() {
		return this._element;
	}
	get value() {
		// チェックボックスの場合選択されている場合のみ値を value を返却
		if (this._element.getAttribute('type') != 'checkbox'
		|| (this._element.getAttribute('type') == 'checkbox' && this._element.checked)
		) {
			return this._element.value;
		}
		return null;
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm 要素
	 */
	init(elm) {
		this._element = elm;
		this._element.addEventListener('blur', e=> {
			this.dispatch({type:this.ON_BLUR, arguments:e});
		});
		this._element.addEventListener('change', e=> {
			this.dispatch({type:this.ON_CHANGE, arguments:e});
		});
	}
	/**
	 * 指定したイベントタイプを実行させる.
	 * @param type 要素
	 */
	trigger(type) {
		switch (type) {
			case this.ON_BLUR:
				this.dispatch({type:this.ON_BLUR, arguments:null});
				break;
			case this.ON_CHANGE:
				this.dispatch({type:this.ON_CHANGE, arguments:null});
				break;
			default:
		}
	}

}

export default Field;
