import Debugger from '../util/Debugger';

const BREAK_POINT = 980;
const ANIMATION_SPEED = 400;
const VISIBLE_CLASS = '-visible';
const ACTIVE_CLASS = 'active';
const SEARCH_CLASS = 'searching';
const ANIMATION_CLASS = 'animation';

/*
 * SP スライドメニュー
 */
class SlideMenu {
  constructor() {
    this.overlay = $('#js-overlay');
    this.menu = $('#js-menu');
    this.trigger = $('#js-header-trigger');
    this.menuMain = $('#js-menu-main');
    this.item = $('.js-menu-item');
    this.searchContainer = $('#js-header-search');
    this.searchArea = $('#js-header-search-area');
    this.input = this.searchContainer.find('#js-header-input');
    this.cancel = this.searchContainer.find('#js-header-cancel');
    // this.parents = [$('.js-header-main'), $('.js-dropdown-area'), $('.js-header-sub')];
    this.itemArr = [];
    this.isMoving = false;
  }

  checkBreakPoint() {
    let width = window.innerWidth;
    if (width >= BREAK_POINT) {
      if (this.isPC) return;
      this.isPC = true;
      this.reset();
    } else {
      if (!this.isPC) return;
      this.isPC = false;
    }
  }
  reset() {
    this.trigger.removeClass(ACTIVE_CLASS);
    this.menu.removeClass(ACTIVE_CLASS);
    this.overlay.removeClass(VISIBLE_CLASS);
    this.searchContainer.removeClass(ANIMATION_CLASS);
    this.item.removeClass(ACTIVE_CLASS);
    setTimeout(() => {
      this.menu.removeClass(SEARCH_CLASS);
    }, ANIMATION_SPEED);
  }
  toggleMenu() {
    this.isMoving = true;
    this.menu.addClass(ANIMATION_CLASS);
    this.trigger.toggleClass(ACTIVE_CLASS);
    this.menu.toggleClass(ACTIVE_CLASS);
    this.overlay.toggleClass(VISIBLE_CLASS);
    if (this.menu.hasClass(ACTIVE_CLASS)) {
      // 表示する
      setTimeout(() => {
        let i = 0;
        let max = this.itemArr.length;

        const addCls = setInterval(() => {
          let target = this.itemArr[i];
          if (i !== max) {
            target.addClass(ACTIVE_CLASS);
            i += 1;
          } else {
            clearInterval(addCls);
          }
        }, 30);
        this.isMoving = false;
      }, 50);
    } else {
      // 隠す
      setTimeout(() => {
        this.item.removeClass(ACTIVE_CLASS);
        this.menu.removeClass(ANIMATION_CLASS).removeClass(SEARCH_CLASS);
        this.isMoving = false;
      }, ANIMATION_SPEED);
    }
  }
  addEventListener() {
    $(window).on('resize', () => {
      this.checkBreakPoint();
    });

    this.trigger.on('touchend mouseup', e => {
      e.preventDefault();
      if (!this.isMoving) {
        this.toggleMenu();
      }
    });

    this.input.on('focus', () => {
      this.searchContainer.addClass(ANIMATION_CLASS);
      this.menu.addClass(SEARCH_CLASS);
    });
    this.cancel.on('click', () => {
      this.menu.removeClass(SEARCH_CLASS);
    });

    // エリア外判定とし、閉じる
    // $(document).on('click', () => {
    //   if (this.menu.hasClass(ACTIVE_CLASS)) {
    //     this.reset(true);
    //   }
    // });
    // メニュー内とトリガーのクリックはカウントしない
    // this.menu.on('click', e => {
    //   e.stopPropagation();
    // });
    // this.trigger.on('click', e => {
    //   e.stopPropagation();
    // });
  }
  init() {
    for (let i = 0; i < this.item.length; i++) {
      this.itemArr.push(this.item.eq(i));
    }
    this.addEventListener();
  }
}

export default SlideMenu;
