import Debugger from '../util/Debugger';

const BREAK_POINT = 980;
const SLIDE_SPEED = 250;
const VISIBLE_CLASS = 'visible';
const ACTIVE_CLASS = 'active';
const LEFT_CLASS = 'left';
const RIGHT_CLASS = 'right';

class Carousel {
  constructor(container, obj) {
    this.container = container;
    this.viewer = this.container.find(obj.viewer);
    this.slider = this.container.find(obj.slider);
    this.item = this.slider.find(obj.item);
    this.trigger = this.container.find(obj.trigger);
    // インジケーター
    this.nav = this.container.find(obj.indicator);
    this.navItem = this.nav.find(obj.indicatorItem);

    this.isAnimated = true;
    this.activeItem = ''; // 現在表示中のスライドエレメント
    this.index = 0;
    this.maxIndex = this.item.length - 1;
    this.currentIndex = 0;
    this.currentPos = 0; // sliderのtranslateX量の管理
    this.isAuto = true; // falseでオートスライドを実行しない
  }
  checkBreakPoint() {
    let width = window.innerWidth;
    if (width >= BREAK_POINT) {
      if (this.isPC) {
        return;
      }
      this.isPC = true;
      this.resizing();
      this.resetSlider();
    } else {
      if (!this.isPC) {
        return;
      }
      this.isPC = false;
      this.resizing();
      this.resetSlider();
    }
  }
  autoSliding() {
    if (!this.isAuto) return false;
    this.autoSlide = setInterval(() => {
      this.playForward();
    }, 600);
    // }, AUTOSLIDE_TIME);
  }
  clearSlideTimer() {
    clearInterval(this.autoSlide);
  }
  onTouchStart(e) {
    this.touchPosX = this.getPosX(e);
    this.touchPosY = this.getPosY(e);
    this.touchDirection = '';
  }
  onTouchMove(e) {
    const moveX = this.touchPosX - this.getPosX(e);
    const moveY = this.touchPosY - this.getPosY(e);
    const moveRate = moveX / moveY;
    // Y軸から15度以上の方向にスワイプした場合はページスクロールをキャンセル
    if (moveRate > Math.tan(15 * Math.PI / 180)) {
      e.preventDefault();
    }
    // スワイプの方向（back／next）を取得（70px以上移動しなければスワイプと判断しない）
    if (moveX > 70) {
      this.touchDirection = 'next';
    } else if (moveX < -70) {
      this.touchDirection = 'back';
    }
  }
  onTouchEnd() {
    if (!this.isAnimated || this.touchDirection === '') return false;
    this.updateIndex(this.touchDirection);
  }
  getPosX(e) {
    return e.originalEvent.touches[0].pageX; // 横方向の座標を取得
  }
  getPosY(e) {
    return e.originalEvent.touches[0].pageY; // 縦方向の座標を取得
  }
  updateNav() {
    this.navItem.removeClass(ACTIVE_CLASS);
    this.navItem.eq(this.index).addClass(ACTIVE_CLASS);
  }
  updateSlide(direction) {
    this.isAnimated = false;

    const before = this.activeItem;
    this.activeItem = this.item.eq(this.index);

    let hide_to = '';
    let show_from = '';
    if (direction === 'next') {
      hide_to = LEFT_CLASS;
      show_from = RIGHT_CLASS;
    } else {
      hide_to = RIGHT_CLASS;
      show_from = LEFT_CLASS;
    }

    before.removeClass(ACTIVE_CLASS).addClass(hide_to);
    this.activeItem.addClass(show_from);
    setTimeout(() => {
      this.activeItem.removeClass(show_from).addClass(ACTIVE_CLASS);
      this.item.removeClass(RIGHT_CLASS).removeClass(LEFT_CLASS); // リセットする
    }, 70);

    // ナビ更新
    this.updateNav();
    setTimeout(() => {
      this.isAnimated = true;
    }, SLIDE_SPEED);
  }
  updateIndex(direction) {
    if (direction === 'next') {
      if (this.index === this.maxIndex) {
        this.index = 0;
      } else {
        this.index++;
      }
    } else if (direction === 'back') {
      if (this.index === 0) {
        this.index = this.maxIndex;
      } else {
        this.index--;
      }
    }
    this.updateSlide(direction);
  }

  init() {
    // 最初にどのスライドを表示するか確認
    for (let i = 0; i < this.item.length; i++) {
      const c = this.item.eq(i);
      if (c.hasClass(ACTIVE_CLASS)) {
        this.activeItem = c;
        this.index = this.activeItem.index(c);
      }
    }
    this.navItem.eq(this.index).addClass(ACTIVE_CLASS);
    // サムネイルの表示アニメーション
    let i = 0;
    let max = this.navItem.length;
    const addCls = setInterval(() => {
      let target = this.navItem.eq(i);
      if (i !== max) {
        target.addClass(VISIBLE_CLASS);
        i += 1;
      } else {
        clearInterval(addCls);
      }
    }, 30);
  }
  start() {
    let width = window.innerWidth;
    // this.isPCの設定

    // 初期設定
    this.init();

    // 自動スライドをスタート

    // 進む・戻る
    this.trigger.on('click', e => {
      if (!this.isAnimated) return false;

      const d = $(e.currentTarget).attr('data-id');
      this.updateIndex(d);
    });

    // インジケータークリック
    this.navItem.on('click', e => {
      const before = this.index;
      this.index = this.navItem.index(e.currentTarget);
      let d = '';
      if (before < this.index) {
        d = 'next';
      } else if (this.index < before) {
        d = 'back';
      }
      this.updateSlide(d);
    });

    // 指でスワイプ
    this.viewer.on('touchstart', e => {
      this.onTouchStart(e);
    });
    this.viewer.on('touchmove', e => {
      this.onTouchMove(e);
    });
    this.viewer.on('touchend', e => {
      this.onTouchEnd(e);
    });
  }
}

export default Carousel;
