import Debugger from '../util/Debugger';

const ANIMATION_SPEED = 400;
const CLOSE_CLASS = '-close';

class FaqAccordion {
  constructor(elm, obj) {
    this.container = elm;
    this.trigger = this.container.find(obj.trigger);
    this.outer = this.container.find(obj.outer);
    this.inner = this.container.find(obj.inner);
    this.isMoving = false;
  }

  open() {
    const h = this.inner.innerHeight();
    this.outer.css({
      'height': h + 'px'
    });
    setTimeout(() => {
      this.outer.css({
        'height': 'auto'
      });
      this.isMoving = false;
    }, ANIMATION_SPEED);
  }
  close() {
    const h = this.outer.innerHeight();
    this.outer.css({
      'height': h + 'px'
    });
    this.outer.css({
      'height': 0
    });
    setTimeout(() => {
      this.isMoving = false;
    }, ANIMATION_SPEED);
  }
  init() {
    this.trigger.on('click', () => {
      if (!this.isMoving) {
        if (this.container.hasClass(CLOSE_CLASS)) {
          this.isMoving = true;
          this.open();
        } else {
          this.isMoving = true;
          this.close();
        }
        this.container.toggleClass(CLOSE_CLASS);
      }
    });
  }
}

export default FaqAccordion;
