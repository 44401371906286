'use strict';

import Validator from './Validator';

/**
 * RadioValidator クラス.
 */
class RadioValidator extends Validator {
	constructor() {
		super();
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm 要素
	 */
	init(elm) {
		super.init(elm);
	}
	/**
	 * カーソルが外れた、値が変更されたときの処理をおこなう.
	 */
	update() {
		this._status = this.STATUS_SUCCESS;
		this._message = '';
		let value = this._element.value;
		// 未選択で且つ必須項目であればエラー
		if (value.empty()) {
			if (this._required) {
				this._status = this.STATUS_ERROR;
				this._message = ((this.lang == 'en') ? this.MESSAGE_ERROR_REQUIRED_INPUT_EN : this.MESSAGE_ERROR_REQUIRED_INPUT);
			}
		}
		// 更新を通知
		this.dispatch({type:this.ON_UPDATE, arguments:this});
	}
}

export default RadioValidator;
