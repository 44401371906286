! function () {

  const DEBUG_MODE = false;

  class Debugger {
    static log(any) {
      if (DEBUG_MODE) console.log(any);
    }

    static line(num, borderColor) {
      if (!DEBUG_MODE) return;
      const elm = '<div></div>';
      $(elm).css({
        'position': 'absolute',
        'z-index': 9999,
        'top': num + 'px',
        'left': 0,
        'width': '100%',
        'height': '1px',
        'background-color': borderColor === undefined ? 'red' : borderColor
      }).prependTo('body');
    }
  }

  module.exports = Debugger;
}();
