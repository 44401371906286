'use strict';

import EventDispatcher from '../../util/EventDispatcher';

/**
 * AutocompleteAddress クラス.
 */
class AutocompleteAddress extends EventDispatcher {
	constructor(ajaxZip3) {
		super();

		this.ON_SUCCESS = 'success';

		// AjaxZip3
		this._ajaxZip3 = ajaxZip3;
		// 要素
		this._element;
	}
	get element() {
		return this._element;
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm ZIP 要素
	 * @param azip1, azip2, apref, aaddr AjaxZip3 へ渡す引数
	 */
	init(elm, azip1, azip2, apref, aaddr) {
		this._element = elm;
		this._element.addEventListener('keyup', e=> {
			this._ajaxZip3.zip2addr(azip1, azip2, apref, aaddr);
		});
		this._ajaxZip3.onSuccess = function(e) {
			// 都道府県と市区町村のイベント実行
			let $pref = document.querySelector('#pref');
//			$pref.onchange();
			if (/*@cc_on ! @*/ false) {
				$pref.fireEvent('onchange');
			} else {
				let e = document.createEvent('MouseEvents');
				e.initEvent('change', false, true);
				$pref.dispatchEvent(e);
			}

			let $address1 = document.querySelector('#address1');
//			$address1.onchange();
			if (/*@cc_on ! @*/ false) {
				$address1.fireEvent('onchange');
			} else {
				let e = document.createEvent('MouseEvents');
				e.initEvent('change', false, true);
				$address1.dispatchEvent(e);
			}

			// イベント実行
			this.dispatch({type:this.ON_SUCCESS, arguments:e});
		}
	}
}

export default AutocompleteAddress;
