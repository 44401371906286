'use strict';

import TextValidator from './TextValidator';

/**
 * TextareaValidator クラス.
 */
class TextareaValidator extends TextValidator {
	constructor() {
		super();
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm 要素
	 */
	init(elm) {
		super.init(elm);
	}
}

export default TextareaValidator;
