'use strict';

import FieldGroup from './FieldGroup';

/**
 * ValidatorGroup クラス.
 */
class ValidatorGroup extends FieldGroup {
	constructor() {
		super();

		this.ON_UPDATE = 'update';

		this.STATUS_SUCCESS = 'success';
		this.STATUS_ERROR = 'error';

		this.MESSAGE_ERROR_REQUIRED_INPUT = '入力してください。';
		this.MESSAGE_ERROR_REQUIRED_SELECT = '選択してください。';

		this.MESSAGE_ERROR_REQUIRED_INPUT_EN = 'The field is required';
		this.MESSAGE_ERROR_REQUIRED_SELECT_EN = 'The field is required';

		// バリデーターグループのID
		this._id;
		// 必須フラグ
		this._required = false;
		// チェック状態
		this._status;
		// メッセージ
		this._message;
		// 言語
		this._lang;
		// イベントが発生したバリデータークラス
		this._current;
	}
	get id() {
		return this._id;
	}
	set id(n) {
		this._id = n;
	}
	get required() {
		return this._required;
	}
	set required(b) {
		this._required = b;
	}
	get status() {
		return this._status;
	}
	get message() {
		return this._message;
	}
	get lang() {
		return this._lang;
	}
	set lang(s) {
		this._lang = s;
	}
	get current() {
		return this._current;
	}
	/**
	 * 初期処理をおこなう.
	 * @param fields バリデータークラス配列
	 */
	init(fields) {
		super.init(fields);

		for (let i=0; i < this._fields.length; i++) {
			this._fields[i].addEventListener(this._fields[i].ON_UPDATE, this.update, this);
		}
	}
	/**
	 * 子要素のカーソルが外れた、値が変更されたときの処理をおこなう.
	 * @param e イベントが発生したバリデータークラス
	 */
	update(e) {
		this._current = e;
	}
	/**
	 * フィールドクラスを追加する.
	 * @param field 追加するフィールドクラス
	 */
	add(field) {
		super.add(field);

		let i = this._fields.length - 1;
		this._fields[i].addEventListener(this._fields[i].ON_UPDATE, this.update, this);
	}
}

export default ValidatorGroup;
