import Debugger from '../util/Debugger';

const ANIMATION_SPEED = 400;
const OPEN_CLASS = '-open';
const ANIMATION_CLASS = '-animation';

class Accordion {
  constructor(container, obj) {
    this.container = container;
    this.trigger = container.find(obj.triggerName);
    this.wrapper = container.find(obj.wrapperName);
    this.list = this.wrapper.children(obj.listName);

    this.isAnimated = true;
    this.isOpen = false;
    this.children = [];
    this.BREAK_POINT = 768;
  }

  updateDevice() {
    let width = window.innerWidth;

    if (width >= this.BREAK_POINT) {
      if (this.isPC) return;
      this.isPC = true;
      this.forcedReset();
    } else {
      if (!this.isPC) return;
      this.isPC = false;
      this.forcedReset();
    }
  }
  initHeight(elm) {
    elm.css('height', 'auto');
  }
  setHeight(value, isAni) {
    if (isAni) {
      this.wrapper.addClass(ANIMATION_CLASS);
    } else {
      this.wrapper.removeClass(ANIMATION_CLASS);
    }
    let c;
    if (value !== 'auto') {
      c = value + 'px';
    } else {
      c = value;
    }
    this.wrapper.css({
      height: c
    });
  }
  open() {
    let h = this.list.innerHeight();
    this.setHeight(h, true);

    setTimeout(() => {
      this.initHeight(this.wrapper);
    }, ANIMATION_SPEED);

    this.container.addClass(OPEN_CLASS);
    this.isOpen = true;
  }
  close() {
    this.setHeight(0, true);
    this.container.removeClass(OPEN_CLASS);
    this.isOpen = false;
    setTimeout(() => {
      for (let i = 0; i < this.children.length; i++) {
        this.children[i].forcedReset();
      }
    }, ANIMATION_SPEED);
  }
  forcedReset(height_val) {
    if (height_val === undefined || height_val === null) height_val = 0;
    this.setHeight(height_val, false);
    this.container.removeClass(OPEN_CLASS);
    this.isOpen = false;
  }
  toggleAccordion(open) {
    // 高さの始点を与える（transitionのため）
    let container_h = this.wrapper.outerHeight();
    this.setHeight(container_h, false);

    if (open) {
      this.open();
    } else {
      this.close();
    }
    setTimeout(() => {
      this.isAnimated = true;
    }, ANIMATION_SPEED);
  }

  start() {
    let width = window.innerWidth;
    this.isPC = width >= this.BREAK_POINT;

    $(window).on('resize', () => {
      this.updateDevice();
    });

    // アコーディオン
    this.trigger.on('click', e => {
      if (!this.isPC) e.preventDefault(); // リンクのキャンセル
      if (this.isAnimated) {
        this.isAnimated = false;

        if (this.container.hasClass(OPEN_CLASS)) {
          this.toggleAccordion(); // 閉じる
        } else {
          this.toggleAccordion(true); // 開く
        }
      } else {
        return false;
      }
    });
  }
}

export default Accordion;
