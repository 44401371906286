'use strict';

/**
 * 登録されているリスナーをディスパッチする.
 */
class EventDispatcher {
	constructor() {
		this._listeners = [];
	}
	/**
	 * イベントリスナーを登録する.
	 * @param type 登録しているイベントのタイプ
	 * @param fn イベントの発生時に呼び出されるメソッド
	 * @param target メソッドの this オブジェクト
	 */
	addEventListener(type, fn, target) {
		this._listeners.push({
			type: type,
			fn: fn,
			target: target
		});
	}
	/**
	 * 登録されているイベントをイベントをディスパッチする.
	 */
	dispatch(e) {
		for (var i=0; i < this._listeners.length; i++) {
			let ls = this._listeners[i];
			if (ls.type == e.type) {
				ls.fn.call(ls.target, e.arguments);
			}
		}
	}
	/**
	 * 指定のイベントのタイプのリスナーが登録されているか返却する.
	 * @param type 登録しているイベントのタイプ
	 * @return 指定のイベントのタイプのリスナーが登録されている場合は true
	 */
	hasEventListener(type) {
		for (var i=0; i < this._listeners.length; i++) {
			if (ls.type == type) {
				return true;
	            break;
	        }
		}
		return false
	}
	/**
	 * イベントリスナーを削除する.
	 * @param type 登録しているイベントのタイプ
	 * @param fn イベントの発生時に呼び出されるメソッド
	 */
	removeEventListener(type, fn) {
		for (var i=0; i < this._listeners.length; i++) {
			if (ls.type != type || ls.fn != fn) {
				this._listeners.splice(i, 1);
	            break;
	        }
		}
	}
}

export default EventDispatcher;
