import Debugger from '../util/Debugger';

const BREAK_POINT = 768;

class SmoothScroll {
  constructor() {
    this.header = $('#js-header');
    this.isScrolling = false;
    this.isPC;
  }

  updateDevice() {
    let width = window.innerWidth;
    if (width >= BREAK_POINT) {
      if (this.isPC) return;
      this.isPC = true;
    } else {
      if (!this.isPC) return;
      this.isPC = false;
    }
  }
  changeScrollFlag() {
    setTimeout(() => {
      this.isScrolling = false;
    }, 1200);
  }
  updateScrollPosition() {
    if ($(this.target).length) {
      this.isScrolling = true;

      $(this.target).velocity('scroll', {
        duration: 1200,
        easing: 'easeOutExpo',
        offset: -this.offset // ズレの指定
      });

      this.changeScrollFlag();
    }
  }
  getOffset() {
    if (this.isPC) {
      return this.header.height() + 15; // 15pxプラスで多少余白とる
    } else {
      return 0;
    }
  }
  init() {
    this.updateDevice();

    // URLに#が含まれ、アンカーへリンクしている場合のヘッダー高さ分の調整
    if (location.hash !== '' && $(location.hash) !== undefined) {
      this.target = location.hash;
      this.offset = this.getOffset();
      this.updateScrollPosition();
    }

    $(window).on('resize', () => {
      this.updateDevice();
    });

    $('a[href^="#"]').on('click', e => {
      if (this.isScrolling) return false;
      e.preventDefault();

      this.target = e.currentTarget.hash;
      if (this.hash == '') return false; // href="#"などの場合は処理中止

      this.offset = this.getOffset();
      this.updateScrollPosition();
    });
  }
}

export default SmoothScroll;
