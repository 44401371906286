'use strict';

import EventDispatcher from '../../util/EventDispatcher';
import Field from './Field';
import FieldGroup from './FieldGroup';
import Validator from './Validator';
import ValidatorGroup from './ValidatorGroup';
import TextValidator from './TextValidator';
import TextareaValidator from './TextareaValidator';
import EMailValidator from './EMailValidator';
import TelValidator from './TelValidator';
import ZipValidator from './ZipValidator';
import SelectValidator from './SelectValidator';
import RadioValidator from './RadioValidator';
import CheckboxValidator from './CheckboxValidator';
import FieldGroupValidator from './FieldGroupValidator';
import CheckboxGroupValidator from './CheckboxGroupValidator';

String.prototype.empty = function() {
	if (this == null || typeof(this) == 'undefined') {
		return true;
	} else if (this.replace(/　/g, '').replace(/ /g, '') == '') {
		return true;
	}
	return false;
}
String.prototype.is_numeric = function() {
	if (this.match(/[^0-9]/g)){
		return false;
	}
	return true;
}
String.prototype.is_email = function() {
//	if (!this.match(/^[\w_\-\.]+@[\w_-]+(\.[\w_-]+){1,}$/)) {
	if (!this.match(/^[a-zA-Z0-9][a-zA-Z0-9\.\_\-]*@[a-zA-Z0-9\_\-]+\.[a-zA-Z0-9\_\-]+[a-zA-Z0-9\_\.\-]*[a-zA-Z0-9\_\-]$/)) {
		return false;
	}
	return true;
}
String.prototype.is_tel = function() {
//	if (!this.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
//		return false;
//	}
	return true;
}
String.prototype.is_postalcode = function() {
	if (this.match(/^\d{3}(\s*|-)\d{4}$/)) {
		return true;
	}
	return false;
}

/**
 * フォームクラス.
 */
class Form {
	constructor() {
		// グループ項目
		this.groups = new Array();
		// フィールド項目
		this.fields = new Array();
		// 必須入力項目数
		this.total = 0;
		// submit ボタン
		this.$submit;
		this.lang = 'jp';
	}
	/**
	 * 初期化する.
	 */
	init() {
	    // 言語を取得
	    let $lang = document.querySelector('[data-lang]');
	    if ($lang) {
	      this.lang = $lang.getAttribute('data-lang');
	    }

		// submit ボタン
		this.$submit = document.querySelector('[data-field-submit]');
		this.$submit.addEventListener('click', function() {
			if (this.getAttribute('data-disabled') == 'false') {
				let $form = document.querySelector('[data-form=' + this.getAttribute('data-field-submit') + ']');
				$form.submit();
			} else {
				alert('未入力項目または入力内容にエラーがあります。');
			}
			return false;
		})
		this.$submit.classList.add('disabled');

		// 必須入力項目数（必須項目＋必須グループ項目）
		this.total = document.querySelectorAll('[data-field-required]').length;

		// Form のグループ要素を取得
		let $groups = document.querySelectorAll('[data-field-group]');
		for (let i=0; i < $groups.length; i++) {
			// グループ要素に含まれる子要素を取得
			let $children = $groups[i].querySelectorAll('[data-field-type]');
			if ($children.length > 0) {
				// data-field-group に対応したグループクラスを生成
				let group = this.createValidator($groups[i].getAttribute('data-field-group'));
				if (group) {
					// クラス初期化
					if (group instanceof CheckboxGroupValidator) {
						group.init($groups[i], new Array());
					} else {
						group.init(new Array());
					}
					group.lang = this.lang;
					for (let j=0; j < $children.length; j++) {
						// 中身の要素にグループに含まれる要素であることを示す属性付与（単独でチェックする要素から除外するため）
						$children[j].setAttribute('data-field-group', 'child');
						let field = this.createValidator($children[j].getAttribute('data-field-type'));
						// クラス初期化
						field.init($children[j]);
						field.lang = this.lang;
						// クラスの必須プロパティを ON
						if ($children[j].getAttribute('data-field-required') == 'true') {
							field.required = true;
						}
						// クラスの許可文字数プロパティに文字数をセット
						if ($children[j].getAttribute('maxlength')) {
							field.maxlength = $fields[i].getAttribute('maxlength');
						}
						field.id = $children[j].getAttribute('id');
						// クラスに子要素を追加
						group.add(field);
					}
					// クラスの必須プロパティを ON
					if ($groups[i].getAttribute('data-field-required') == 'true') {
						group.required = true;
					}
					// グループの子要素でイベントが発生したときの関数（update）を指定
					group.addEventListener(group.ON_UPDATE, this.update, this);
					group.id = $groups[i].getAttribute('id');
					this.groups.push(group);
				}
			}
		}

		// Form の要素を取得
		let $fields = document.querySelectorAll('[data-field-type]');
		for (let i=0; i < $fields.length; i++) {
			// グループ要素に含まれない要素を処理
			if ($fields[i].getAttribute('data-field-group') != 'child') {
				// data-field-type に対応したグループクラスを生成
				let field = this.createValidator($fields[i].getAttribute('data-field-type'));
				if (field) {
					// クラス初期化
					field.init($fields[i]);
					field.lang = this.lang;
					// クラスの必須プロパティを ON
					if ($fields[i].getAttribute('data-field-required') == 'true') {
						field.required = true;
					}
					// クラスの許可文字数プロパティに文字数をセット
					if ($fields[i].getAttribute('maxlength')) {
						field.maxlength = $fields[i].getAttribute('maxlength');
					}
					// 要素でイベントが発生したときのメソッド（update）を指定
					field.addEventListener(field.ON_UPDATE, this.update, this);
					field.id = $fields[i].getAttribute('id');
					this.fields.push(field);
				}
			}
		}

		// 初期化の際にすでに値がセットされているとき、確認画面から戻ってきたと判断
		let back = false;
		for (let i=0; i < $fields.length; i++) {
			if ($fields[i].getAttribute('data-field-group') != 'child' && $fields[i].value != '') {
				back = true;
				break;
			}
		}
		// 再訪問であればチェック強制実行
		if (back) {
			for (let i=0; i < this.groups.length; i++) {
//				this.groups[i].update();
				for (let j=0; j < this.groups[i].fields.length; j++) {
					this.groups[i].fields[j].update();
				}
			}
			for (let i=0; i < this.fields.length; i++) {
				this.fields[i].update();
			}
			let $unit1 = document.querySelector('[data-field-unit=purchase_y]');
			if ($unit1) {
				if (!$unit1.classList.contains('-success')) $unit1.classList.add('-success');
				let $field1 = document.querySelector('#purchase_y');
				$field1.setAttribute('data-field-error', 'false');
			}
			let $unit2 = document.querySelector('[data-field-unit=purchase_m]');
			if ($unit2) {
				if (!$unit2.classList.contains('-success')) $unit2.classList.add('-success');
				let $field2 = document.querySelector('#purchase_m');
				$field2.setAttribute('data-field-error', 'false');
			}
		}
	}

	/**
	 * フィールドの更新時の処理を行う.
	 * @param e イベントが発生した Validator クラス
	 */
	update(e) {
		// エラーメッセージを表示
		let id = e.id;
		if (id) {
			// エラーメッセージの表示要素を取得（グループであれば複数フィールドを包括する要素のID）
			let $message = document.querySelector('[data-field-message=' + id + ']');
			$message.textContent = e.message;
			// 状態クラス付与
			let field;
			if (e instanceof FieldGroupValidator) {	
				field = e.current;
			} else if (e instanceof CheckboxGroupValidator) {	
				field = e;
			} else {
				field = e;
			}
			if (field) {
				let $unit = document.querySelector('[data-field-unit=' + field.id + ']');
				if (field.status == field.STATUS_ERROR) {
					// data-field-error に true をセット
					field.element.setAttribute('data-field-error', 'true');
					if ($unit.classList.contains('-success')) $unit.classList.remove('-success');
					if (!$unit.classList.contains('-error')) $unit.classList.add('-error');
				} else {
					// data-field-error に false をセット
					field.element.setAttribute('data-field-error', 'false');
					if (!$unit.classList.contains('-success')) $unit.classList.add('-success');
					if ($unit.classList.contains('-error')) $unit.classList.remove('-error');
				}
			}
		}

		// data-field-error = true の数が 0 且つ 全ての必須入力項目のチェック状態が STATUS_SUCCESS であれば OK
		let errors = document.querySelectorAll('[data-field-error=true]').length;
		let entered = 0;
		if (errors == 0) {
			// Form のグループ要素うち必須項目且つ入力が OK の項目数をカウント
			for (let i=0; i < this.groups.length; i++) {
				if (this.groups[i] instanceof CheckboxGroupValidator) {
					if (this.groups[i].required && this.groups[i].status == this.groups[i].STATUS_SUCCESS) {
						entered++;
					}
				} else {
					for (let j=0; j < this.groups[i].fields.length; j++) {
						if (this.groups[i].fields[j].required && this.groups[i].fields[j].status == this.groups[i].fields[j].STATUS_SUCCESS) {
							entered++;
						}
					}
				}
			}
			// Form の要素うち必須項目且つ入力が OK の項目数をカウント
			for (let i=0; i < this.fields.length; i++) {
				if (this.fields[i].required && this.fields[i].status == this.fields[i].STATUS_SUCCESS) {
					entered++;
				}
			}
		}
		let $unit = document.querySelector('[data-field-unit=submit]');
		if (errors == 0 && entered == this.total) {
			this.$submit.setAttribute('data-disabled', 'false');
//			if (this.$submit.classList.contains('disabled')) this.$submit.classList.remove('disabled');
			if ($unit.classList.contains('-disabled')) $unit.classList.remove('-disabled');
		} else {
			this.$submit.setAttribute('data-disabled', 'true');
//			if (!this.$submit.classList.contains('disabled')) this.$submit.classList.add('disabled');
			if (!$unit.classList.contains('-disabled')) $unit.classList.add('-disabled');
		}
	}

	/**
	 * インスタンスを生成して返却する.
	 * @param type Validator クラスを識別する文字列
	 * @return Validator クラス
	 */
	createValidator(type) {
		let field;
		switch (type) {
			case 'text':
				field = new TextValidator();
				break;
			case 'email':
				field = new EMailValidator();
				break;
			case 'tel':
				field = new TelValidator();
				break;
			case 'textarea':
				field = new TextareaValidator();
				break;
			case 'zip':
				field = new ZipValidator();
				break;
			case 'select':
				field = new SelectValidator();
				break;
			case 'radio':
				field = new RadioValidator();
				break;
			case 'checkbox':
				field = new CheckboxValidator();
				break;
			case 'field-group':
				field = new FieldGroupValidator();
				break;
			case 'checkbox-group':
				field = new CheckboxGroupValidator();
				break;
			default:
		}
		return field;
	}
}

export default Form;
