'use strict';

import EventDispatcher from '../../util/EventDispatcher';

/**
 * FieldGroup クラス.
 */
class FieldGroup extends EventDispatcher {
	constructor() {
		super();

		// フィールドクラス配列
		this._fields = new Array();
	}
	get id() {
		return this._id;
	}
	set id(n) {
		this._id = n;
	}
	get fields() {
		return this._fields;
	}
	/**
	 * 初期処理をおこなう.
	 * @param fields フィールドクラス配列
	 */
	init(fields) {
		this._fields = fields;
	}
	/**
	 * フィールドクラスを追加する.
	 * @param field 追加するフィールドクラス
	 */
	add(field) {
		this._fields.push(field);
	}
}

export default FieldGroup;
