'use strict';

import Validator from './Validator';

/**
 * EMailValidator クラス.
 */
class EMailValidator extends Validator {
	constructor() {
		super();
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm 要素
	 */
	init(elm) {
		super.init(elm);
	}
	/**
	 * カーソルが外れた、値が変更されたときの処理をおこなう.
	 */
	update() {
		this._status = this.STATUS_SUCCESS;
		this._message = '';
		let value = this._element.value;
		// 空で且つ必須項目であればエラー
		if (value.empty()) {
			if (this._required) {
				this._status = this.STATUS_ERROR;
				this._message = ((this.lang == 'en') ? this.MESSAGE_ERROR_REQUIRED_INPUT_EN : this.MESSAGE_ERROR_REQUIRED_INPUT);
			}
		} else {
			// メールフォーマットでない場合エラー
			if (!value.is_email()) {
				this._status = this.STATUS_ERROR;
				this._message = ((this.lang == 'en') ? this.MESSAGE_ERROR_FORMAT_EMAIL_EN : this.MESSAGE_ERROR_FORMAT_EMAIL);
			// 許可文字数を超過していた場合エラー（this._maxlength が 0 の場合は無視）
			} else if (this._maxlength > 0 && value.length > this._maxlength) {
				this._status = this.STATUS_ERROR;
				this._message = ((this.lang == 'en') ? this.MESSAGE_ERROR_MAXLENGTH_EN : this._maxlength + this.MESSAGE_ERROR_MAXLENGTH);
			}
		}
		// 更新を通知
		this.dispatch({type:this.ON_UPDATE, arguments:this});
	}
}

export default EMailValidator;
