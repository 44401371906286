!function () {

  let agent = navigator.userAgent;
  let ppVersion = navigator.appVersion.toLowerCase();

  module.exports = {
    browser: {
      isIE: /MSIE/.test(navigator.userAgent),
      isIE11: /Trident/.test(navigator.userAgent),
      isEdge: /Edge/.test(navigator.userAgent),
      isFireFox: /Firefox/.test(navigator.userAgent),
      isChrome: /Chrome/.test(navigator.userAgent)
    },
    device: {
      isMac: agent.indexOf('Mac') >= 0,
      isIPad: /iPad/.test(agent),
      isIOS: agent.indexOf('iPhone') > -1 || agent.indexOf('iPod') > -1 || agent.indexOf('iPad') > -1,
      isAndroid: agent.indexOf('Android') > -1
    }
  }

  /*
  /* 追加ブラウザ判定
  **/
  module.exports.browser.isSafari = /Safari/.test(agent) && !module.exports.browser.isChrome;

  /*
  /* 追加デバイス判定
  **/
  module.exports.device.isMobile = module.exports.device.isIOS || module.exports.device.isAndroid;
  if (module.exports.device.isAndroid) {
    module.exports.device.isAndroidTablet = agent.indexOf('Mobile') < 0;
    module.exports.device.isAndroidMobile = !module.exports.device.isAndroidTablet;
  } else {
    module.exports.device.isAndroidTablet = false;
    module.exports.device.isAndroidMobile = false;
  }

  module.exports.device.isSmartPhone = false;
  if (module.exports.device.isMobile) {
    if ((agent.indexOf('iPhone') > -1) || (module.exports.device.isAndroid && !module.exports.device.isAndroidTablet)) {
      module.exports.device.isSmartPhone = true;
    }
  }

} ();
