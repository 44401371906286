import Debugger from '../util/Debugger';

const SCROLL_CLASS = '-scrolling';
const ANIMATION_CLASS = '-animation';
const VISIBLE_CLASS = '-visible';
const FIXED_CLASS = '-fixed';
const SIMPLE_CLASS = '-simple';
const ANIMATION_SPEED = 200;

class HeaderScroll {
  // constructor(elm, point, className) {
  constructor(obj) {
    this.header = obj.elm;
    this.header_h = this.header.outerHeight();
    this.checkPoint = obj.point === undefined ? 200 : obj.point;
    Debugger.log(this.checkPoint);
    this.scrollTop = $(window).scrollTop();
    this.CLASS_NAME = obj.className;
    this.isMoving = false;
  }

  /**
   * ヘッダーのクラスをつけかえる
   * @param state クラスの付与／削除
   */
  changeCls(state) {
    if (state === 'add') {
      this.header.addClass(FIXED_CLASS);
      setTimeout(() => {
        // アニメーションプロパティをセット
        this.header.addClass(ANIMATION_CLASS);
        setTimeout(() => {
          // 最後にVISIBLE付与でスワイプインさせる
          this.header.addClass(VISIBLE_CLASS);
        }, 10);
      }, 10);
    } else if (state === 'remove') {
      this.header.removeClass(VISIBLE_CLASS); // まずスワイプアウトで非表示にする
      setTimeout(() => {
        // もとの位置に戻す
        this.header.removeClass(ANIMATION_CLASS).removeClass(FIXED_CLASS);
      }, ANIMATION_SPEED);
    }
  }
  /**
   * ヘッダーを表示する基準値を超えているかを判別する
   */
  checkScrollPoint() {
    if (this.scrollTop > this.checkPoint) {
      if (!this.header.hasClass(FIXED_CLASS)) this.changeCls('add');
    } else {
      if (this.header.hasClass(FIXED_CLASS)) this.changeCls('remove');
    }
  }
  /**
   * 初期処理
   */
  init() {
    if (!this.header.length || this.header.hasClass(SIMPLE_CLASS)) return false;

    this.scrollTop = $(window).scrollTop();
    this.checkScrollPoint();

    $(window).on('scroll', () => {
      this.scrollTop = $(window).scrollTop();
      this.checkScrollPoint();
    });
  }
}

export default HeaderScroll;
