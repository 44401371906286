'use strict';

import addBrowserClass from './util/browser-class';
import addDeviceClass from './util/device-class';
// 通常処理関連
import SmoothScroll from './ui/SmoothScroll';
import ScrollChangeCls from './ui/ScrollChangeCls';
import HeaderScroll from './ui/HeaderScroll';
import HeaderAccordion from './ui/HeaderAccordion';
import SlideMenu from './ui/SlideMenu';
import Accordion from './ui/Accordion';
import FooterAccordion from './ui/FooterAccordion';
import FixedAnchor from './ui/FixedAnchor';
import FaqAccordion from './ui/FaqAccordion';
import Carousel from './ui/Carousel';
import Form from './ui/form/Form';
import AutocompleteAddress from './ui/form/AutocompleteAddress';

const start = () => {
  const root = $('#root-container');
  const header = $('#js-header');
  const pageTop = $('#js-page-top');

  // アンカーリンクなどのスムーススクロール
  const smoothScroll = new SmoothScroll();
  smoothScroll.init();

  // ヘッダーのスクロール時固定
  const headerObj = {
    elm: header,
    point: 140,
    className: 'scrolling'
  };
  const headerScroll = new HeaderScroll(headerObj);
  headerScroll.init();

  // ヘッダーのアコーディオンメニュー
  const headerAccordionObj = {
    triggerName: '.js-header-accordion-trigger',
    parentName: '.js-header-accordion',
    childName: '.js-header-accordion-child',
    childTriggerName: '.js-header-accordion-child-trigger',
    listName: '.js-header-accordion-list',
    childListName: '.js-header-accordion-child-list',
    overlay: '#js-overlay'
  };
  const headerAccordion = new HeaderAccordion(header, headerAccordionObj);
  headerAccordion.init();

  // SP スライドメニュー
  const slideMenu = new SlideMenu();
  slideMenu.init();
  slideMenu.overlay.on('click touchend', () => {
    if (slideMenu.menu.hasClass('active')) {
      slideMenu.reset(true); // SPスライドメニューを閉じる
      setTimeout(() => {
        // 内部アコーディオンリセットの終了を待ってから
        headerAccordion.forcedReset(); // 内部アコーディオンのリセット
      }, 400);
    }
  });

  // ページトップ戻るボタンの表示制御
  const pageTopObj = {
    elm: pageTop,
    point: 200,
    className: 'visible'
  };
  const dispPageTop = new ScrollChangeCls(pageTopObj);
  dispPageTop.init();

  // フッターのSPアコーディオン
  const footerAccordionContainer = $('.js-accordion');
  const footerAccordionObj = {
    triggerName: '.js-accordion-trigger',
    wrapperName: '.js-accordion-wrapper',
    listName: '.js-accordion-list'
  };
  const footerAccordionArray = [];
  for (let i = 0; i < footerAccordionContainer.length; i++) {
    const container = footerAccordionContainer.eq(i);
    footerAccordionArray.push(
      new FooterAccordion(container, footerAccordionObj)
    );
    footerAccordionArray[i].start();
  }

  // 特長の固定アンカーリンクモジュール
  const fixedAnchorObj = {
    BREAK_POINT: 980,
    header: header,
    menu: $('.js-fixed-outline'),
    item: $('.js-fixed-outline-menu'),
    section: $('.js-fixed-outline-section'),
    topElm: $('.js-fixed-outline-top'),
    bottomElm: $('.js-fixed-outline-bottom'),
    withHeader: true
  };
  const fixedAnchor = new FixedAnchor(fixedAnchorObj);
  fixedAnchor.init();

  // お知らせ詳細の追従型SNSボタン
  const fixedSNSButtonObj = {
    BREAK_POINT: 980,
    header: header,
    menu: $('.js-fixed-sns'),
    // 'item': $('.js-fixed-sns-menu'),
    section: $('.js-fixed-sns-section'),
    topElm: $('.js-fixed-sns-top'),
    bottomElm: $('.js-fixed-sns-bottom'),
    withHeader: true
  };
  const fixedSNSButton = new FixedAnchor(fixedSNSButtonObj);
  fixedSNSButton.init();

  // よくある質問のアコーディオン
  const faqAccordionElm = $('.js-faq');
  const faqAccordionObj = {
    trigger: '.js-faq-trigger',
    outer: '.js-faq-answer',
    inner: '.js-faq-inner'
  };
  for (let i = 0, len = faqAccordionElm.length; i < len; i++) {
    const faqAccordion = new FaqAccordion(
      faqAccordionElm.eq(i),
      faqAccordionObj
    );
    faqAccordion.init();
  }

  // 製品仕様のカルーセル
  const productCarouselContainer = $('.js-carousel');
  const productCarouselObj = {
    viewer: '.js-carousel-viewer',
    slider: '.js-carousel-slider',
    item: '.js-carousel-item',
    indicator: '.js-carousel-indicator',
    indicatorItem: '.js-carousel-indi-item',
    trigger: '.js-carousel-trigger'
  };
  for (let i = 0; i < productCarouselContainer.length; i++) {
    const productCarousel = new Carousel(
      productCarouselContainer.eq(i),
      productCarouselObj
    );
    productCarousel.start();
  }

  // Form
  if (document.querySelectorAll('[data-form]').length > 0) {
    let form = new Form();
    form.init();
    if (document.querySelectorAll('.js-autocomplete-address').length > 0) {
      // AjaxZip3
      let autocompleteAddress = new AutocompleteAddress(AjaxZip3);
      let $zip = document.querySelectorAll('.js-autocomplete-address')[0];
      autocompleteAddress.init($zip, 'zip', '', 'pref', 'address1');
    }
  }
};

$(window).on('load', function() {
  start();
});
