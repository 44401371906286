import Debugger from '../util/Debugger';

const BREAK_POINT = 980;
const ANIMATION_SPEED_SLOW = 400;
const ANIMATION_CLASS = '-animation';
const OPEN_CLASS = '-open';
const VISIBLE_CLASS = '-visible';


class HeaderAccordion {
  constructor(header, obj) {
    this.header = header;
    this.overlay = $(obj.overlay);
    this.trigger = this.header.find(obj.triggerName);
    this.parent = this.header.find(obj.parentName);
    this.child = this.header.find(obj.childName);
    this.childTrigger = this.header.find(obj.childTriggerName);
    this.list = this.header.find(obj.listName);
    this.childList = this.header.find(obj.childListName);

    this.isAnimated = true;
    this.isOpen = false;
  }

  updateDevice() {
    let width = window.innerWidth;

    if (width >= BREAK_POINT) {
      if (this.isPC) return;
      this.isPC = true;
      this.forcedReset();
    } else {
      if (!this.isPC) return;
      this.isPC = false;
      this.forcedReset();
    }
  }

  setHeight(target, value, isAni) {
    if (isAni) {
      target.addClass(ANIMATION_CLASS);
    } else {
      target.removeClass(ANIMATION_CLASS);
    }
    let c;
    if (value !== 'auto') {
      c = value + 'px';
    } else {
      c = value;
    }
    target.css({
      height: c
    });
  }

  /**
   * アコーディオンをリセット
   */
  forcedReset() {
    Debugger.log('------ forcedReset() ------');
    this.header.removeClass(OPEN_CLASS);
    this.overlay.removeClass(VISIBLE_CLASS);
    this.trigger.removeClass(OPEN_CLASS);
    this.parent
      .removeClass(ANIMATION_CLASS)
      .removeClass(OPEN_CLASS)
      .css({
        'height': ''
      });
    this.childTrigger.removeClass(OPEN_CLASS);
    this.child
      .removeClass(ANIMATION_CLASS)
      .removeClass(VISIBLE_CLASS)
      .css({
        'height': ''
      });
    this.isOpen = false;
  }

  open(obj, isAnimation) {
    if (obj.child !== undefined) obj.child.addClass(VISIBLE_CLASS); // 第2階層の選択
    obj.trigger.addClass(OPEN_CLASS);
    this.header.addClass(OPEN_CLASS);
    // PC処理
    if (this.isPC) this.overlay.addClass(VISIBLE_CLASS);
    // 高さ付与
    const h = obj.list.outerHeight();
    Debugger.log('isAnimation: ' + isAnimation);
    this.setHeight(obj.parent, h, isAnimation);
    // アニメーション終了後の処理
    setTimeout(() => {
      this.setHeight(obj.parent, 'auto', false);
      obj.parent.removeClass(ANIMATION_CLASS);
      this.isOpen = true;
      this.isAnimated = true;
    }, ANIMATION_SPEED_SLOW);
  }

  close(obj, isAnimation) {
    Debugger.log('isAnimation: ' + isAnimation);
    if (isAnimation) {
      this.child.addClass(ANIMATION_CLASS);
    } else {
      this.child.removeClass(ANIMATION_CLASS);
    }

    if (this.isPC) {
      this.overlay.removeClass(VISIBLE_CLASS);
      this.trigger.removeClass(OPEN_CLASS);
      this.header.removeClass(OPEN_CLASS);

      if (obj === null) {
        // どれか開いたままの切り替え動作
        this.setHeight(this.parent, 0, isAnimation);
      } else {
        // すべて閉じる場合（現在の高さを指定）
        this.setHeight(obj.parent, obj.parent.outerHeight(), false);
        setTimeout(() => {
          this.setHeight(this.parent, 0, isAnimation);
        }, 10);
      }

      setTimeout(() => {
        this.parent.removeClass(ANIMATION_CLASS);
        this.isOpen = false;
        this.isAnimated = true;
      }, ANIMATION_SPEED_SLOW);
    }
    // SP
    else {
      obj.trigger.removeClass(OPEN_CLASS);
      this.setHeight(obj.parent, 0, isAnimation);
      // obj.parent.css({
      //   'height': 0
      // });
      setTimeout(() => {
        this.isAnimated = true;
      }, ANIMATION_SPEED_SLOW);
    }
  }

  toggleSPAccordion(obj) {
    // 始点作成
    obj.parent.removeClass(ANIMATION_CLASS).css({
      height: obj.parent.outerHeight() + 'px'
    });

    if (obj.trigger.hasClass(OPEN_CLASS)) {
      Debugger.log('SPでtriggerがOPENもってる');
      this.close(obj, true);
    } else {
      this.open(obj, true);
    }
  }

  getObj(trigger) {
    const obj = {
      trigger: trigger,
      parent: trigger.next(this.parent),
      list: trigger.next(this.parent).find(this.list)
    };
    return obj;
  }

  init() {
    let width = window.innerWidth;
    this.isPC = width >= BREAK_POINT;

    $(window).on('resize', () => {
      this.updateDevice();
    });

    // アコーディオン
    this.trigger.on('click', e => {
      if (this.isAnimated) {
        this.isAnimated = false;
        const trigger = $(e.currentTarget);
        const obj = this.getObj(trigger);

        if (this.isPC) {
          if (!trigger.hasClass(OPEN_CLASS)) { // もっていなかったら

            if (this.isOpen) {
              // いずれかが開かれている
              this.close(null, false);
              this.open(obj, false);
            } else {
              // どれも開かれていない
              this.open(obj, true);
            }
          } else { // もっている
            Debugger.log('open もっている');
            this.close(obj, true);
          }
        }
        // SP
        else {
          this.toggleSPAccordion(obj);
        }
      }
    });

    // 範囲外（オーバーレイ）クリックで閉じる動作
    this.overlay.on('click touchend', () => {
      if (this.isPC && this.isOpen) {
        // 今開いているドロップダウンを特定する
        let trigger;
        for (let i = 0; i < this.trigger.length; i++) {
          if (this.trigger.eq(i).hasClass(OPEN_CLASS)) trigger = this.trigger.eq(i);
        }
        const obj = this.getObj(trigger);
        this.close(obj, true);
      }
    });
  }
}

export default HeaderAccordion;
