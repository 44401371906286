import Debugger from '../util/Debugger';
const VISIBLE_CLASS = 'is-visible';

class ScrollChangeCls {
  // constructor(elm, point, className) {
  constructor(obj) {
    this.target = obj.elm;
    this.checkPoint = obj.point === undefined ? 200 : obj.point;
    this.scrollTop = $(window).scrollTop();
    this.CLASS_NAME = obj.className;
  }
  changeCls(state) {
    if (state === 'add' && !this.target.hasClass(this.CLASS_NAME)) {
      this.target.addClass(this.CLASS_NAME);
    } else if (state === 'remove' && this.target.hasClass(this.CLASS_NAME)) {
      this.target.removeClass(this.CLASS_NAME);
    }
  }
  checkScrollPoint() {
    if (this.scrollTop > this.checkPoint) {
      this.changeCls('add');
    } else {
      this.changeCls('remove');
    }
  }
  init() {
    if (!this.target.length) return false;

    $(window).on('scroll', () => {
      this.scrollTop = $(window).scrollTop();
      this.checkScrollPoint();

      this.scrollHeight = $(document).height();
      this.scrollPosition = $(window).height() + $(window).scrollTop();
      this.footHeight = $('.l-footer').innerHeight();

      const BREAK_POINT = 1500;
      let width = window.innerWidth;
      if (width >= BREAK_POINT) {
        if (this.scrollHeight - this.scrollPosition <= this.footHeight - 70) {
          $('#js-page-top').css({
            position: 'absolute',
            bottom: this.footHeight - 26
          });
        } else {
          $('#js-page-top').css({
            position: 'fixed',
            bottom: 60
          });
        }
      }
      if (width < BREAK_POINT) {
        if (this.scrollHeight - this.scrollPosition <= this.footHeight - 54) {
          $('#js-page-top').css({
            position: 'absolute',
            bottom: this.footHeight - 17
          });
        } else {
          $('#js-page-top').css({
            position: 'fixed',
            bottom: 60
          });
        }
      }
    });
  }
}

export default ScrollChangeCls;
