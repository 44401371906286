'use strict';

import ValidatorGroup from './ValidatorGroup';

/**
 * FieldGroupValidator クラス.
 */
class FieldGroupValidator extends ValidatorGroup {
	constructor() {
		super();
	}
	/**
	 * 初期処理をおこなう.
	 * @param fields バリデータークラス配列
	 */
	init(fields) {
		super.init(fields);
	}
	/**
	 * カーソルが外れた、値が変更されたときの処理をおこなう.
	 * 子要素のカーソルが外れた、値が変更されたときの処理をおこなう.
	 * @param e イベント発生フィールドクラス
	 */
	update(e) {
		super.update(e);

		// 保持しているバリデータークラスのステータスを確認
		this._status = '';
		this._message = '';
		for (let i=0; i < this._fields.length; i++) {
			// ステータスがエラーのフィールドのステータスとメッセージを保持
			if (this._fields[i].status == this._fields[i].STATUS_ERROR) {
				this._status = this.STATUS_ERROR;
				this._message = ((this.lang == 'en') ? this.MESSAGE_ERROR_REQUIRED_SELECT_EN : this.MESSAGE_ERROR_REQUIRED_SELECT);
				break;
			}
			this._status = this.STATUS_SUCCESS;
		}
		// 更新を通知
		this.dispatch({type:this.ON_UPDATE, arguments:this});
	}
}

export default FieldGroupValidator;
