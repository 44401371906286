'use strict';

import ValidatorGroup from './ValidatorGroup';

/**
 * CheckboxGroupValidator クラス.
 */
class CheckboxGroupValidator extends ValidatorGroup {
	constructor() {
		super();

		// 要素
		this._element;
	}
	get element() {
		return this._element;
	}
	/**
	 * 初期処理をおこなう.
	 * @param elm 要素
	 * @param fields バリデータークラス配列
	 */
	init(elm, fields) {
		super.init(fields);
		this._element = elm;
	}
	/**
	 * カーソルが外れた、値が変更されたときの処理をおこなう.
	 * 子要素のカーソルが外れた、値が変更されたときの処理をおこなう.
	 * @param e イベント発生フィールドクラス
	 */
	update(e) {
		super.update(e);

		// 保持しているバリデータークラスのステータスを確認
		this._status = '';
		this._message = '';
		if (this._required) {
			for (let i=0; i < this._fields.length; i++) {
				// ステータスがエラーのフィールドのステータスとメッセージを保持
				if (this._fields[i].value != null) {
					this._status = this.STATUS_SUCCESS;
					this._message = '';
					break;
				}
				this._status = this.STATUS_ERROR;
				this._message = ((this.lang == 'en') ? this.MESSAGE_ERROR_REQUIRED_SELECT_EN : this.MESSAGE_ERROR_REQUIRED_SELECT);
			}
		}
		// 更新を通知
		this.dispatch({type:this.ON_UPDATE, arguments:this});
	}
}

export default CheckboxGroupValidator;
